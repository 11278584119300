// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!./components/PageAboutUs/PageAboutUs.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .body {
  background-image: url(./images/page-background.png);
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;;GAEG","sourcesContent":["@import url(./components/PageAboutUs/PageAboutUs.css);\n\n/* .body {\n  background-image: url(./images/page-background.png);\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
