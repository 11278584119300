// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pageAboutUsImage {
  position: absolute;
  top: 0;
  /* left: 10%; */
  left: 0;
  height: 100%;
  max-width: 150%;
  width: 50%;
}

@media screen and (max-width: 900px) {
  .pageAboutUsImage {
    width: 70%;
  }
}

@media screen and (max-width: 600px) {
  .pageAboutUsImage {
    width: 100%;
    left: 0%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PageAboutUs/PageAboutUs.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,eAAe;EACf,OAAO;EACP,YAAY;EACZ,eAAe;EACf,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,WAAW;IACX,QAAQ;EACV;AACF","sourcesContent":[".pageAboutUsImage {\n  position: absolute;\n  top: 0;\n  /* left: 10%; */\n  left: 0;\n  height: 100%;\n  max-width: 150%;\n  width: 50%;\n}\n\n@media screen and (max-width: 900px) {\n  .pageAboutUsImage {\n    width: 70%;\n  }\n}\n\n@media screen and (max-width: 600px) {\n  .pageAboutUsImage {\n    width: 100%;\n    left: 0%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
